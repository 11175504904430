export const UploadIcon = ({size = '16', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M15.9994 16.6672C15.6307 16.6672 15.3322 16.3688 15.3322 16C15.3322 15.6312 15.6307 15.3328 15.9994 15.3328C17.4697 15.3328 18.6666 14.1359 18.6666 12.6656C18.6666 11.2656 17.5713 10.0984 16.1744 10.0078L15.7807 9.98438L15.6135 9.62969C14.9541 8.23437 13.5354 7.33281 11.9994 7.33281C10.4635 7.33281 9.04473 8.23437 8.38535 9.62969L8.21816 9.98438L7.82598 10.0094C6.42754 10.1 5.33379 11.2672 5.33379 12.6672C5.33379 14.1375 6.53066 15.3344 8.00098 15.3344C8.36973 15.3344 8.66816 15.6328 8.66816 16.0016C8.66816 16.3703 8.36973 16.6687 8.00098 16.6687C5.79473 16.6687 4.00098 14.875 4.00098 12.6687C4.00098 10.6984 5.44629 9.03594 7.35566 8.725C8.29316 7.05781 10.076 6 11.9994 6C13.9229 6 15.7057 7.05781 16.6447 8.72188C18.5541 9.03281 19.9994 10.6969 19.9994 12.6672C19.9994 14.8719 18.2057 16.6672 15.9994 16.6672Z"
        fill="#9E6CF7"
      />
      <path
        d="M14 16.3328C13.8297 16.3328 13.6594 16.2672 13.5281 16.1375L12 14.6094L10.4719 16.1375C10.2125 16.3985 9.78906 16.3985 9.52969 16.1375C9.26875 15.8766 9.26875 15.4547 9.52969 15.1953L11.5297 13.1953C11.7891 12.9344 12.2125 12.9344 12.4719 13.1953L14.4719 15.1953C14.7328 15.4563 14.7328 15.8781 14.4719 16.1375C14.3406 16.2688 14.1703 16.3328 14 16.3328Z"
        fill="#797979"
      />
      <path
        d="M12.0002 19C11.6314 19 11.333 18.7016 11.333 18.3328V13.6672C11.333 13.2984 11.6314 13 12.0002 13C12.3689 13 12.6674 13.2984 12.6674 13.6672V18.3344C12.6674 18.7016 12.3689 19 12.0002 19Z"
        fill="#797979"
      />
    </svg>
  );
};
