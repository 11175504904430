import {cast, flow, types} from 'mobx-state-tree';
import api from '@shared/configs/axiosConfig';

import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {REPRICER_STRATEGY, TRepricerStrategyValues} from '@src/shared/enums/repricer-strategy';
import {SHOP_PLACEMENT} from '@src/shared/enums/shop-placement';
import {
  TGetItemFilters,
  TPriceMonitoringItem,
  TPriceMonitoringSearchItem,
  TShopPlacementType,
  TUpdateItemFromWs,
} from './types';

import {mappedCompetitorsPricesFromWs} from './utils/mapped-competitors-price-from-ws';
import {TMpAccountItem} from '../market-place-account-item/types';

export const PriceMonitoringItemShop = types.model('PriceMonitoringItemShop', {
  id: types.identifierNumber,
  price: types.number,
  shopId: types.number,
  storageCount: types.number,
  shopName: types.string,
  marketShopId: types.number,
  shopPlacementType: types.maybeNull(types.enumeration('ShopPlacement', Object.values(SHOP_PLACEMENT))), //Тип доставки магазина
  minPrice: types.optional(types.number, 0), //Минимальная цена, ниже которой не снижаем
  isAuto: types.optional(types.boolean, false), //Авто стратегия (автоматичесое изменение цены)
  margin: types.maybeNull(types.number), //Маржинальность
});

export const CompetitorsPrices = types.model('CompetitorsPrices', {
  price: types.number, //цена товара с возможными скидками
  isSelfPrice: types.boolean, //наша цена
  position: types.number, //позиция в выдаче
  totalOffers: types.number, //кол-во предложений
  modelPrices: types.model('ModelPrices', {
    minPrice: types.number, //мин цена на товар
    maxPrice: types.number, //макс цена на товар
    avgPrice: types.number, //средняя цена на товар
  }),
  shop: types.model('CompetitorPriceShop', {
    name: types.string, //название магазина
    rating: types.number, //рейтинг магазина
  }),
});

export const AnotherPlacePrice = types.model('AnotherPlacePrice', {
  anotherMinPrice: types.maybeNull(types.number),
  anotherPlaceName: types.maybeNull(types.string),
  anotherUri: types.maybeNull(types.string),
});

export const RepricerShopItem = types.model('RepricerShopItem', {
  shopId: types.number,
  auto: types.boolean,
  minPrice: types.number,
  maxPrice: types.maybeNull(types.number), //Максимальная цена, ниже которой не повышаем
});

export const BestMarketOffer = types.model('BestMarketOffer', {
  price: types.maybeNull(types.number),
  shopName: types.maybeNull(types.string),
});

export const PriceMonitoringItem = types.model('PriceMonitoringItem', {
  itemId: types.identifierNumber,
  sku: types.string,
  name: types.string,
  isActive: types.boolean,
  vendor: types.string,
  category: types.string,
  picture: types.maybeNull(types.string),
  price: types.number,
  minPrice: types.number, //Минимальная цена товара для репрайсера
  shops: types.optional(types.array(PriceMonitoringItemShop), []),
  marketSku: types.number,
  marketProductId: types.number,
  currentPrice: types.maybeNull(types.number), //текущая цена
  recommendedPrice: types.maybeNull(types.number),
  attractivePrice: types.maybeNull(types.number), //Привлекательная цена
  moderateAttractivePrice: types.maybeNull(types.number), //умеренная привлекательная цена
  customerPrice: types.maybeNull(types.number), //Ваша цена для покупателей
  cofinancePrice: types.number, //Цена для софинансирования с маркетплейсом
  lastRecommendedAt: types.maybeNull(types.string),
  competitorsPrices: types.maybeNull(types.array(CompetitorsPrices)),
  strategy: types.maybeNull(
    // Стратегия репрайсера
    types.enumeration('Strategy', [
      REPRICER_STRATEGY.OFF,
      REPRICER_STRATEGY.COFINANCE_THRESHOLD,
      REPRICER_STRATEGY.PLACE,
      REPRICER_STRATEGY.DEFAULT,
    ])
  ),
  // Параметры для стратегии
  strategyParams: types.model('StrategyParams', {
    place: types.maybeNull(types.number),
    discountPercent: types.maybeNull(types.number),
  }),
  bestMarketOffer: BestMarketOffer, //лучшее предложение на рынке
  anotherPlacePrice: types.optional(AnotherPlacePrice, {}),
  repricerShopItems: types.optional(types.array(RepricerShopItem), []),
  //deprecated?

  // priceListUri: types.maybeNull(types.string),
  // currentFeePct: types.maybeNull(types.number),
  // isAuto: types.optional(types.boolean, false),
  // margin: types.maybeNull(types.number),
});

export const PageParams = types.model('PageParams', {
  offset: types.optional(types.number, 0),
  limit: types.optional(types.number, 20),
  count: types.optional(types.number, 0),
});

export const SelectedFilters = types.model('SelectedFilters', {
  shops: types.optional(types.array(types.model({id: types.number, name: types.string})), []),
  categories: types.optional(types.array(types.model({id: types.number, name: types.string})), []),
  vendors: types.optional(types.array(types.model({id: types.number, name: types.string})), []),
  groups: types.optional(types.array(types.model({id: types.number, name: types.string})), []),
  search: types.optional(types.string, ''),
});

export const PriceMonitoringStore = types
  .model('PriceMonitoringStore', {
    items: types.optional(types.array(PriceMonitoringItem), []),
    selectedFilters: types.optional(SelectedFilters, {}),
    repricerItemsIds: types.optional(types.array(types.number), []),
    pageParams: types.optional(PageParams, {}),
    isLoadingItems: types.maybe(types.boolean),
  })
  .actions((self) => ({
    getProductsByFilters: flow(function* (marketPlaceAccountId: number, offset: number, filters: TGetItemFilters) {
      const {vendorsIds, categoriesIds, search, groupsIds, shopsIds} = filters;
      const itemsData = yield api.post(`/item/list`, {
        pagination: {
          offset,
          limit: 100,
        },
        filters: {
          categoryIds: categoriesIds && categoriesIds?.length > 0 ? categoriesIds : undefined,
          groupIds: groupsIds && groupsIds?.length > 0 ? groupsIds : undefined,
          shopIds: shopsIds && shopsIds?.length > 0 ? shopsIds : undefined,
          vendorIds: vendorsIds && vendorsIds?.length > 0 ? vendorsIds : undefined,
          search: search && search?.length > 0 ? search : undefined,
        },
        marketPlaceAccountId,
      });
      const {data} = itemsData;

      return {
        list: data.items.map((item: TMpAccountItem) => ({
          itemId: item.id,
          name: item.name,
          picture: item.picture,
          category: item.category,
          vendor: item.vendor,

          sku: item.sku,
          shops: item.shops,
          links: {
            priceLink: null,
          },
        })),
        count: data.count,
        offset: offset,
      } as {
        list: TPriceMonitoringSearchItem[];
        count: number;
        offset: number;
      };
    }),

    getRepricerItemsList: flow(function* ({
      marketPlaceAccountId,
      filters,
      isHiddenLoading = false,
      offset = 0,
      limit = 20,
    }: {
      marketPlaceAccountId: number;
      filters?: TGetItemFilters;
      isHiddenLoading?: boolean;
      offset?: number;
      limit?: number;
    }) {
      try {
        self.isLoadingItems = isHiddenLoading ? false : true;

        if (!filters) {
          filters = {
            vendorsIds: [],
            categoriesIds: [],
            search: '',
          };
        }
        const {vendorsIds, categoriesIds, search, groupsIds, shopsIds} = filters;
        const itemsData = yield api.post(`/repricer/list`, {
          marketPlaceAccountId,
          filters: {
            categoryIds:
              categoriesIds && categoriesIds?.length > 0
                ? categoriesIds
                : self.selectedFilters.categories.length > 0
                  ? self.selectedFilters.categories?.map((item) => item.id)
                  : undefined,
            groupIds:
              groupsIds && groupsIds?.length > 0
                ? groupsIds
                : self.selectedFilters.groups.length > 0
                  ? self.selectedFilters.groups?.map((item) => item.id)
                  : undefined,
            vendorIds:
              vendorsIds && vendorsIds?.length > 0
                ? vendorsIds
                : self.selectedFilters.vendors.length > 0
                  ? self.selectedFilters.vendors?.map((item) => item.id)
                  : undefined,
            shopsIds:
              shopsIds && shopsIds?.length > 0
                ? shopsIds
                : self.selectedFilters.shops.length > 0
                  ? self.selectedFilters.shops?.map((item) => item.id)
                  : undefined,
            search:
              search && search?.length > 0
                ? search
                : self.selectedFilters.search
                  ? self.selectedFilters.search
                  : undefined,
          },
          orders: ['id'],
          pagination: {
            offset: offset ? offset : self.pageParams.offset,
            limit: isHiddenLoading ? self.pageParams.limit : limit ? limit : self.pageParams.limit,
          },
        });

        self.pageParams.offset = itemsData.data.offset;
        self.pageParams.limit = itemsData.data.limit;
        self.pageParams.count = itemsData.data.count;
        self.repricerItemsIds = itemsData.data.repricerItemsIds;
        self.items = itemsData.data.items.map((item: TPriceMonitoringItem) => {
          const sortOrder: {[key in TShopPlacementType]: number} = {
            FBS: 1,
            FBY: 2,
            DBS: 3,
          };
          const shops = item.shops.map((shop) => ({
            ...shop,
            price: shop.price / 100,
          }));
          const sortedShops = shops.sort((a, b) => {
            const orderA = sortOrder[a.shopPlacementType as TShopPlacementType] ?? Number.MAX_VALUE;
            const orderB = sortOrder[b.shopPlacementType as TShopPlacementType] ?? Number.MAX_VALUE;

            return orderA - orderB;
          });
          return {
            ...item,
            price: item.price / 100,
            minPrice: item.minPrice / 100,
            marketProductId: item.marketProductId,
            marketSku: item.marketSku,
            cofinancePrice: item.cofinancePrice / 100,
            attractivePrice: item.attractivePrice ? item.attractivePrice / 100 : null,
            recommendedPrice: item.recommendedPrice ? item.recommendedPrice / 100 : null,
            bestMarketOffer: {
              ...item.bestMarketOffer,
              price: item.bestMarketOffer.price ? item.bestMarketOffer.price / 100 : null,
            },
            currentPrice: item.currentPrice ? item.currentPrice / 100 : null,
            customerPrice: item.customerPrice ? item.customerPrice / 100 : null,
            moderateAttractivePrice: item.moderateAttractivePrice ? item.moderateAttractivePrice / 100 : null,
            anotherPlacePrice: {
              ...item.anotherPlacePrice,
              anotherMinPrice: item.anotherPlacePrice.anotherMinPrice
                ? item.anotherPlacePrice.anotherMinPrice / 100
                : null,
            },
            competitorsPrices:
              item.competitorsPrices &&
              item.competitorsPrices.map((cp) => ({
                ...cp,
                price: cp.price / 100,
                modelPrices: {
                  minPrice: cp.modelPrices.minPrice / 100,
                  maxPrice: cp.modelPrices.maxPrice / 100,
                  avgPrice: cp.modelPrices.avgPrice / 100,
                },
              })),
            shops: sortedShops.map((shop) => ({
              ...shop,
              minPrice:
                (item.repricerShopItems.find((repricerShopItem) => shop.shopId === repricerShopItem.shopId)?.minPrice ||
                  0) / 100,
              isAuto:
                item.repricerShopItems.find((repricerShopItem) => shop.shopId === repricerShopItem.shopId)?.auto ||
                false,
            })),
            repricerShopItems: item.repricerShopItems.map((repricerShopItem) => ({
              ...repricerShopItem,
              minPrice: repricerShopItem.minPrice / 100,
              maxPrice: repricerShopItem.maxPrice ? repricerShopItem.maxPrice / 100 : null,
            })),
          };
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      } finally {
        self.isLoadingItems = false;
      }
    }),
    setSelectedShops: function (shops: {id: number; name: string}[]) {
      self.selectedFilters.shops = cast(shops);
    },
    setSelectedCategories: function (categories: {id: number; name: string}[]) {
      self.selectedFilters.categories = cast(categories);
    },
    setSelectedVendors: function (vendors: {id: number; name: string}[]) {
      self.selectedFilters.vendors = cast(vendors);
    },
    setSelectedGroups: function (groups: {id: number; name: string}[]) {
      self.selectedFilters.groups = cast(groups);
    },
    setSearch: function (search: string) {
      self.selectedFilters.search = search;
    },
    resetFilters: function () {
      self.selectedFilters = cast({
        search: '',
        shops: [],
        categories: [],
        vendors: [],
        groups: [],
      });
    },
    setOffsetItems: function (offset: number) {
      self.pageParams.offset = offset;
    },
    setItemsOnPage: function (limit: number) {
      self.pageParams.limit = limit;
    },
    updateItemMinPrice: flow(function* (shopItemId: number, minPrice: number) {
      try {
        yield api.patch(`/repricer/${shopItemId}/min_price`, {
          minPrice,
        });
        const findIndex = self.items.findIndex((item) => item.itemId === shopItemId);
        if (findIndex !== -1) {
          self.items[findIndex].minPrice = minPrice / 100;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemMaxPrice: flow(function* (shopItemId: number, maxPrice: number) {
      try {
        yield api.patch(`/repricer/${shopItemId}/max_price`, {
          maxPrice,
        });
        const findIndex = self.items.findIndex((item) => item.itemId === shopItemId);
        if (findIndex !== -1) {
          self.items[findIndex].minPrice = maxPrice / 100;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemShopPrice: flow(function* (shopItemId: number, price: number, shopId: number) {
      try {
        yield api.patch(`/shops/${shopId}/items/${shopItemId}/price`, {
          price,
          shopId,
        });
        const findIndex = self.items.findIndex((item) => item.itemId === shopItemId);
        if (findIndex !== -1) {
          const findIndexShop = self.items[findIndex].shops.findIndex((shop) => shop.shopId === shopId);
          if (findIndexShop !== -1) {
            self.items[findIndex].shops[findIndexShop].price = price / 100;
          }
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    addItems: flow(function* (itemsIds: number[]) {
      try {
        yield api.post(`/repricer/add`, {
          itemsIds,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    deleteItem: flow(function* (itemId: number) {
      try {
        yield api.delete(`/repricer/${itemId}`);
        const findIndex = self.items.findIndex((item) => item.itemId === itemId);
        if (findIndex !== -1) {
          self.items.splice(findIndex, 1);
        }
        const findIndexItemIds = self.repricerItemsIds.findIndex((item) => item === itemId);
        if (findIndexItemIds !== -1) {
          self.repricerItemsIds.splice(findIndexItemIds, 1);
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemsOnState: function (items: Map<number, TUpdateItemFromWs>) {
      const updatedItems = self.items.map((item) => {
        const updatedItem = items.get(item.itemId);
        if (updatedItem) {
          return {
            ...item,
            cofinancePrice: Number(updatedItem.cofinance_price) / 100,
            competitorsPrices: mappedCompetitorsPricesFromWs(updatedItem.competitors_prices),
            lastRecommendedAt: updatedItem.last_recommended_at,
            recommendedPrice: updatedItem.recommended_price
              ? updatedItem.recommended_price / 100
              : item.recommendedPrice,
            strategy: updatedItem.strategy,
            strategyParams: {
              place: updatedItem.strategy_params?.place,
              discountPercent: updatedItem.strategy_params?.discount_percent,
            },
          };
        } else {
          return item;
        }
      });

      self.items = cast(updatedItems as TPriceMonitoringItem[]);
    },
    updateStrategy: flow(function* (itemId: number, strategy: TRepricerStrategyValues) {
      try {
        yield api.patch(`/repricer/${itemId}/strategy`, {strategy});

        const findIndex = self.items.findIndex((item) => item.itemId === itemId);
        if (findIndex !== -1) {
          self.items[findIndex].strategy = strategy;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateStrategyParams: flow(function* (
      itemId: number,
      {place, discountPercent}: {place: number | null; discountPercent: number | null}
    ) {
      try {
        yield api.patch(`/repricer/${itemId}/strategy_params`, {place, discountPercent});

        const findIndex = self.items.findIndex((item) => item.itemId === itemId);
        if (findIndex !== -1) {
          self.items[findIndex].strategyParams.place = place;
          self.items[findIndex].strategyParams.discountPercent = discountPercent;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateAutoRepricer: flow(function* (itemId: number, updateData: {shopId: number; auto: boolean}) {
      try {
        yield api.patch(`/repricer/${itemId}/auto`, updateData);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    removeAllItems: flow(function* (marketPlaceAccountId: number) {
      try {
        yield api.delete(`/repricer/`, {params: {marketPlaceAccountId}});
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    uploadFileFeed: flow(function* ({file, marketPlaceAccountId}: {file: File; marketPlaceAccountId: number}) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('marketPlaceAccountId', marketPlaceAccountId.toString());

        const r = yield api.post('repricer/items/upload', formData);

        if (r.status === 200) {
          return r;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
  }));
