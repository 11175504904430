import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Modal} from '@src/components/common/modal';
import {IS_VIEWED_LAST_CHANGE_LOG_VERSION} from '@src/shared/const/localStorage';
import {useStore} from '@src/stores';
import {LOGS_TYPES} from './consts';
import {TLogType} from './types';

import {LogItem} from './log-item/log-item';
import packageJson from '../../../../../package.json';

const logs: {
  date: string;
  items: TLogType[];
}[] = [
  {
    date: '08.10.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Показываем количество товаров, добавленное в Мониторинг цен',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Товар становится неактивным в Мониторинге цен, если он снят с витрины или имеет 0 остаток',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили (?) на дополнительные условия стратегий, где объяснили как работают приоритеты, при задании нескольких условий разных типов.',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'В сводных показателях Аналитики можно будет смотреть данные по категориям и брендам, с помощью фильтра',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'В Аналитику добавили историю остатков по товарам и коэффициент оборачиваемости',
      },
    ],
  },
  {
    date: '25.09.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content:
          'Отображение акций на товар - теперь на главной странице в списке товаров будем отображать есть ли товар в акции, каким способом он туда попал и название акции',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Дополнительное условие для стратегий биддера по акции - если товар попадает в акцию, то можно будет понизить/повысить ставку Буста',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Загрузку товаров в Мониторинг цен таблицей - можно будет скачать шаблон, заполнить его и сразу загрузить в сервис с указанием минимальных цен и стратегий. После успешной загрузки товары сразу появятся в Мониторинге цен и стратегии начнут работать',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Аналитику по магазинам кабинета',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Аналитику Буста по доставленным товарам',
      },
      {
        type: LOGS_TYPES.update,
        content: 'Категории теперь как на Маркете - настраивать стратегии стало привычнее',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'Смена работы с базовыми или ценами магазинов для Мониторинга цен - работает без ошибок',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'Оптимизировали получение данных по брендам - повторение брендов станет меньше',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'В Аналитике статусы Заказа более точные, теперь органика - это органика и появился Буст Маркета.',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'В Аналитике Метрики трафика показывают правильные значения',
      },
    ],
  },
  {
    date: '11.09.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Теперь будем уведомлять обо всех улучшениях и обновлениях сервиса',
      },

      {
        type: LOGS_TYPES.feature,
        content: 'Стратегии для Мониторинга цен - "Быть на позиции" и "Лучшая цена"',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Стратегия "Выключено" для Мониторинга цен, чтобы получать данные о конкурентах без автостратегий',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Кнопка “Удалить все”, чтобы удалить товары из Мониторинга цен за один раз',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'График изменения цены на товар',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'В Аналитику вернули обновленные Метрики просмотров и добавлений в корзину',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Новые настройки стратегий в Бусте продаж, стало удобнее и понятнее. Дополнительные условия работы стратегии теперь легче настраивать',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Улучшили информацию о ценах для товаров добавленных в Мониторинг цен: теперь помимо рекомендаций Маркета мы выводим конкурентов, их цены, рейтинг и позицию',
      },
    ],
  },
];

export const ChangelogModal = observer(() => {
  const {isOpen: isOpenTour} = useTour();
  const {user} = useStore();

  const [isOpen, setIsOpen] = useState(false);

  const currentAppVersion = packageJson.version;
  const appVersionOnLocalStorage = localStorage.getItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION);

  useEffect(() => {
    let intervalId = null;
    if (appVersionOnLocalStorage !== currentAppVersion && user.isAuth && !isOpenTour) {
      intervalId = setTimeout(() => setIsOpen(true), 6000);
    }
    if (isOpenTour) {
      setIsOpen(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpenTour, user.isAuth]);

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION, currentAppVersion);
  };

  return (
    <Modal isCloseOnClickOverlay={false} title="История обновлений" opened={isOpen} onClose={onClose}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 35, padding: 1, maxWidth: 800}}>
        {logs.map((log) => (
          <LogItem key={log.date} date={log.date} items={log.items} />
        ))}
      </div>
    </Modal>
  );
});
