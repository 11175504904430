import {lazy} from 'react';

import {SummaryAnalyticsPage} from '@src/pages/analytics/summary-analytics/summary-analytics';
import {PartnerPage} from '@src/pages/partner/partner';

import {
  APP_ROUTES,
  getRouteAnalytics,
  getRouteAnalyticsDeprecated,
  getRouteAnalyticsDev,
  getRouteGroups,
  getRouteLogin,
  getRoutePartner,
  getRoutePriceMonitoring,
  getRoutePricing,
  getRouteProducts,
  getRouteRules,
  getRouteSettings,
  getRouteSummaryAnalytics,
  getRouteYandexAuth,
} from '../const/router';
import {AppRoutesProps} from '../types/router';

const ProductsPage = lazy(() => import('../../pages/products'));
const RulesPage = lazy(() => import('../../pages/rules'));
const GroupsPage = lazy(() => import('../../pages/groups'));

const AnalyticsPage = lazy(() => import('../../pages/analytics'));
const AnalyticsDevPage = lazy(() => import('../../pages/analytics/analytics-dev'));
const AnalyticsDeprecatedPage = lazy(() => import('../../pages/analytics/analytics-deprecated'));

const LoginPage = lazy(() => import('../../pages/login'));
const YandexAuthPage = lazy(() => import('../../pages/yandex-auth'));
const NotFoundPage = lazy(() => import('../../pages/not-found'));
const PriceMonitoringPage = lazy(() => import('../../pages/price-monitoring'));
const PricingPage = lazy(() => import('../../pages/pricing'));
const SettingsPage = lazy(() => import('../../pages/settings'));

export const routeConfig: Record<APP_ROUTES, AppRoutesProps> = {
  [APP_ROUTES.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginPage />,
  },
  [APP_ROUTES.PARTNER]: {
    path: getRoutePartner(),
    element: <PartnerPage />,
  },
  [APP_ROUTES.YANDEX_AUTH]: {
    path: getRouteYandexAuth(),
    element: <YandexAuthPage />,
  },
  [APP_ROUTES.PRODUCTS]: {
    path: getRouteProducts(),
    element: <ProductsPage />,
    authOnly: true,
  },
  [APP_ROUTES.GROUPS]: {
    path: getRouteGroups(),
    element: <GroupsPage />,
    authOnly: true,
  },
  [APP_ROUTES.RULES]: {
    path: getRouteRules(),
    element: <RulesPage />,
    authOnly: true,
  },
  [APP_ROUTES.PRICE_MONITORING]: {
    path: getRoutePriceMonitoring(),
    element: <PriceMonitoringPage />,
    authOnly: true,
  },

  [APP_ROUTES.PRICING]: {
    path: getRoutePricing(),
    element: <PricingPage />,
    authOnly: true,
  },
  [APP_ROUTES.ANALYTICS]: {
    path: getRouteAnalytics(),
    element: <AnalyticsPage />,
    authOnly: true,
  },
  [APP_ROUTES.ANALYTICS_DEV]: {
    path: getRouteAnalyticsDev(),
    element: <AnalyticsDevPage />,
    authOnly: true,
  },
  [APP_ROUTES.ANALYTICS_DEPRECATED]: {
    path: getRouteAnalyticsDeprecated(),
    element: <AnalyticsDeprecatedPage />,
    authOnly: true,
  },
  [APP_ROUTES.SUMARRY_ANALYTICS]: {
    path: getRouteSummaryAnalytics(),
    element: <SummaryAnalyticsPage />,
    authOnly: true,
  },
  [APP_ROUTES.SETTINGS]: {
    path: getRouteSettings(),
    element: <SettingsPage />,
    authOnly: true,
  },
  // last
  [APP_ROUTES.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
  },
};
