import {ERROR_CODES} from '../const/error-codes';

export const handlingConsoleRequestError = (error: unknown) => {
  //@ts-ignore
  const errorCode = error.response.data.error_code;
  let message;
  if (error instanceof Error) message = ERROR_CODES[errorCode as keyof typeof ERROR_CODES] || error.message;
  if (message) {
    throw new Error(message);
  }
};
