import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {declOfNum} from '@shared/utils/declOfNum';
import {Button, Divider, DropdownAccounts, Tooltip, Typography, UserProfileDropdown} from '@src/components';
import {CustomDropdown} from '@src/components/common/custom-dropdown';
import {CaseIcon} from '@src/components/common/icons';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {COLORS} from '@src/shared/const/appPalette';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import {getRoutePricing} from '@src/shared/const/router';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';
import {useStore} from '@src/stores';
import dayjs from 'dayjs';

import {useEmotionWrapper} from './header.s';

const helpMenuList = [
  {
    title: 'Консультация',
    onClick: () => {
      window.open(RESOURCE_URLS.SUPPORT_TELEGRAM, '_blank');
    },
  },
  {
    title: 'Запись на демо',
    onClick: () => {
      window.open(RESOURCE_URLS.CONSULTATION, '_blank');
    },
  },
  {
    title: 'База знаний',
    onClick: () => {
      window.open(RESOURCE_URLS.INSTRUCTION, '_blank');
    },
  },
];

export const Header = observer(() => {
  const {user, mpAccounts, tariffs} = useStore();
  const {currentTariff} = useCurrentTariff();
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();

  const getCurrentTariff = useMemo(() => {
    if (mpAccounts.items.length === 0) {
      return null;
    }
    if (currentTariff) {
      const startDate = dayjs(new Date());
      const endDate = dayjs(currentTariff.periodTo);
      const diffDays = endDate.diff(startDate, 'day');
      const tariffName = currentTariff.type === TARIFFS_TYPES.START ? 'Тарифы' : currentTariff.name;

      if (currentTariff.type === TARIFFS_TYPES.START || currentTariff.isTrial) {
        return (
          <div className={classes.tariffText} onClick={() => navigate(getRoutePricing())}>
            <Button onClick={() => navigate(getRoutePricing())}>
              {currentTariff.isTrial ? `Бесплатно ${declOfNum(diffDays, ['день', 'дня', 'дней'])}` : tariffName}
            </Button>
          </div>
        );
      }

      return null;
    }
  }, [tariffs.list.length, mpAccounts.current?.id, currentTariff.periodFrom, currentTariff.periodTo]);

  return (
    <div className={classes.root}>
      <div className={classes.flexContainer}>
        {(mpAccounts.count || 0) > 0 && (
          <div className={classes.accountBlock}>
            <div className={classes.yandexMarketTitle}>
              <img src="/png/yandex-market-logo.png" height={24} width={24} />
              <Typography weight={600} whiteSpace="nowrap" size={14} color={COLORS.DARK_100}>
                Яндекс Маркет
              </Typography>
            </div>
            <div className={classes.accountDropdown} id="onboarding-current-market-place-account">
              <CaseIcon color={COLORS.DARK_100} />
              <Tooltip content="Кабинет зарегистрированный на Я.Маркет">
                <Typography weight={600} color={COLORS.DARK_100}>
                  Кабинет:{' '}
                </Typography>
              </Tooltip>
              <DropdownAccounts />
            </div>
          </div>
        )}
        {user.isAuth && (
          <div className={classes.rightBlock}>
            <CustomDropdown items={helpMenuList} />
            <div className={classes.currentTariffBlock}>
              {getCurrentTariff}
              <UserProfileDropdown />
            </div>
          </div>
        )}
      </div>
      <div className={classes.divider}>
        <Divider />
      </div>
    </div>
  );
});
